<template>
	<div class="rootContainer">
		<div class="topContainer">
			<layout-top />
		</div>
		<div class="mainContainer">
			<layout-main />
		</div>
		<div class="footContainer">
			<layout-foot />
		</div>
	</div>
</template>

<script>
	import LayoutTop from "./components/top.vue";
	import LayoutMain from "./components/main.vue";
	import LayoutFoot from "./components/foot.vue";
	export default {
		name: "Layout",
		components: {
			LayoutTop,
			LayoutMain,
			LayoutFoot
		},
		methods: {

		},
		data() {
			return {

			};
		},
		created() {

		}
	}
</script>

<style>
</style>
