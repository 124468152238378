import Vue from 'vue';
import VueRouter from 'vue-router';
import IndexLayout from '@/views/layout/indexLayout.vue';
import DetailLayout from '@/views/layout/detailLayout.vue'


Vue.use(VueRouter);

const routes = [{
	path: "/index",
	name: "index",
	component: IndexLayout,
	meta: {
		name: "首页",
	},
	children: [{
		path: "/index",
		name: "index",
		component: () => import("@/views/index.vue"),
		meta: {
			name: "首页"
		}
	}]
},{
	path: "/",
	name: "index",
	component: IndexLayout,
	meta: {
		name: "首页",
	},
	children: [{
		path: "/",
		name: "index",
		component: () => import("@/views/index.vue"),
		meta: {
			name: "首页"
		}
	}]
},{
	path: "/about",
	name: "about",
	component: DetailLayout,
	meta: {
		name: "关于我们",
	},
	children: [{
		path: "/about",
		name: "about",
		component: () => import("@/views/about.vue"),
		meta: {
			name: "关于我们"
		}
	}]
},{
	path: "/qualification",
	name: "qualification",
	component: DetailLayout,
	meta: {
		name: "关于我们",
	},
	children: [{
		path: "/qualification",
		name: "qualification",
		component: () => import("@/views/qualification.vue"),
		meta: {
			name: "企业资质"
		}
	}]
},{
	path: "/history",
	name: "history",
	component: DetailLayout,
	meta: {
		name: "发展历程",
	},
	children: [{
		path: "/history",
		name: "history",
		component: () => import("@/views/history.vue"),
		meta: {
			name: "企业资质"
		}
	}]
},{
	path: "/map",
	name: "map",
	component: DetailLayout,
	meta: {
		name: "营销网络",
	},
	children: [{
		path: "/map",
		name: "map",
		component: () => import("@/views/map.vue"),
		meta: {
			name: "营销网络"
		}
	}]
},{
	path: "/service",
	name: "service",
	component: DetailLayout,
	meta: {
		name: "电网数据服务",
	},
	children: [{
		path: "/service",
		name: "service",
		component: () => import("@/views/service.vue"),
		meta: {
			name: "电网数据服务"
		}
	}]
},{
	path: "/product",
	name: "product",
	component: DetailLayout,
	meta: {
		name: "电力物联网",
	},
	children: [{
		path: "/product",
		name: "product",
		component: () => import("@/views/product.vue"),
		meta: {
			name: "电力物联网"
		}
	}]
},{
	path: "/power",
	name: "power",
	component: DetailLayout,
	meta: {
		name: "电网实施(运维)服务",
	},
	children: [{
		path: "/power",
		name: "power",
		component: () => import("@/views/power.vue"),
		meta: {
			name: "电网实施(运维)服务"
		}
	}]
},{
	path: "/news",
	name: "news",
	component: DetailLayout,
	meta: {
		name: "公司动态",
	},
	children: [{
		path: "/news",
		name: "news",
		component: () => import("@/views/news.vue"),
		meta: {
			name: "公司动态"
		}
	}]
},{
	path: "/joinus",
	name: "joinus",
	component: DetailLayout,
	meta: {
		name: "加入我们",
	},
	children: [{
		path: "/joinus",
		name: "joinus",
		component: () => import("@/views/join.vue"),
		meta: {
			name: "加入我们"
		}
	}]
},{
	path: "/newsDetail",
	name: "newsDetail",
	component: DetailLayout,
	meta: {
		name: "新闻详情",
	},
	children: [{
		path: "/newsDetail",
		name: "newsDetail",
		component: () => import("@/views/newsDetail.vue"),
		meta: {
			name: "新闻详情"
		}
	}]
},{
	path: "/joinDetail",
	name: "joinDetail",
	component: DetailLayout,
	meta: {
		name: "加入我们详情页",
	},
	children: [{
		path: "/joinDetail",
		name: "joinDetail",
		component: () => import("@/views/joinDetail.vue"),
		meta: {
			name: "加入我们详情页"
		}
	}]
}]

const router = new VueRouter({
	mode: "hash",
	base: process.env.BASE_URL,
	routes
})
export default router
