import "babel-polyfill"
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import uuid from 'uuid/v1'
import echarts from 'echarts'
import "font-awesome/css/font-awesome.css"
import * as swiperAni from "./assets/js/swiper.animate1.0.3.min.js"
Vue.config.productionTip = false
axios.defaults.baseURL = "http://auto-sense.com.cn:8092"
//axios.defaults.withCredentials = true;
axios.defaults.crossDomain = true;
Vue.prototype.axios = axios;
Vue.prototype.uuid = uuid;
Vue.prototype.$echarts = echarts;
Vue.prototype.$swiperAnimateCache = swiperAni.swiperAnimateCache;
Vue.prototype.$swiperAnimate = swiperAni.swiperAnimate;
Vue.prototype.$clearSwiperAnimate = swiperAni.clearSwiperAnimate;
new Vue({
  router,
  render: h => h(App),
}).$mount('#container')
