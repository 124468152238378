<template>
	<div class="rootContainer">
		<div class="topContainer">
			<layout-top />
		</div>
		<div class="mainContainer" :style="{height:(windowHeight-topHeight)+'px'}">
			<layout-main />
		</div>
	</div>
</template>

<script>
	import LayoutTop from "./components/top.vue";
	import LayoutMain from "./components/main.vue";
	export default {
		name: "Layout",
		components: {
			LayoutTop,
			LayoutMain
		},
		methods: {

		},
		data() {
			return {
				windowHeight:0,
				topHeight:0
			};
		},
		created() {
			let _this = this;
			window.onresize = function(){
				_this.windowHeight = $(window).height();
				_this.topHeight = $(".topContainer").height();
			}
			window.setTimeout(function(){
				_this.windowHeight = $(window).height();
				_this.topHeight = $(".topContainer").height();
			},100);
		}
	}
</script>

<style>
	.rootContainer{
		background-color: #999;
	}
</style>
